import { Container } from "@material-ui/core"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react"
import { normalizePath } from "../../utils/get-url-path"
import Layout from "../layout"
import PageTitle from '../pagebuilder-parts/PageTitle'
function BlogPost({ data, location }) {
  const { nextPage, previousPage, page } = data
  const { title, content, featuredImage } = page

  return (
    <Layout>
      <PageTitle title={title} location={location}  />

      {!!featuredImage?.node?.localFile?.childImageSharp && (
        <div>
          <GatsbyImage image={featuredImage.node.localFile.childImageSharp.gatsbyImageData} />
        </div>
      )}
      <Container>

      <p dangerouslySetInnerHTML={{ __html: content }} />

      <br />
      {!!nextPage && (
        <Link to={normalizePath(nextPage.uri)}>Next: {nextPage.title}</Link>
      )}
      <br />
      {!!previousPage && (
        <Link to={normalizePath(previousPage.uri)}>
          Previous: {previousPage.title}
        </Link>
      )}
      </Container>
    </Layout>
  );
}

export default BlogPost
